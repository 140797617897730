import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MdxLayout from 'components/mdx-layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <MdxLayout title="Recipes" subline="Usefull tips for working with Backbreeze">
    {children}
  </MdxLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "overwrite-a-utility-class-name"
    }}>{`Overwrite a utility class name`}</h2>
    <p>{`To overwrite a classname change the `}<inlineCode parentName="p">{`name`}</inlineCode>{` key in the property config variable:`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// in your project variables file

$bb-text-align-config: (
  'name': 'text-align', // <- Custom name here
  'prop': 'text-align',
  'bps': $bb-bps,
  'values': $bb-text-align-values
);
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Will yield:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* result shortend for brevity */

.text-align-center {
  text-align: center:
}

.text-align-left {
  text-align: left:
}

/* ... */
`}</code></pre>
    <h2 {...{
      "id": "use-custom-breakpoints-for-a-utility-class"
    }}>{`Use custom breakpoints for a utility class.`}</h2>
    <p>{`To use custom breakpoints pass a custom breakpoint map to the `}<inlineCode parentName="p">{`bps`}</inlineCode>{` key in the property config variable:`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// in your project variables file

$bb-text-align-config: (
  'name': 'ta',
  'prop': 'text-align',
  'bps': (
    'medium': 62em,
    'large': 1600px,
  ),
  'values': $bb-text-align-values
);
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Will yield:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* result shortend for brevity */
.ta-center {
  /* ... */
}

@media all and (min-width: 62em) {
  .ta-center\\@medium {
    /* ... */
  }
}

@media all and (min-width: 1600em) {
  .ta-center\\@large {
    /* ... */
  }
}

/* ... */
`}</code></pre>
    <h3 {...{
      "id": "add-a-breakpoint-to-the-existing-ones"
    }}>{`Add a breakpoint to the existing ones.`}</h3>
    <p>{`To just add a new breakpoint to a utility keeping the existing ones you will need to extend the default `}<a parentName="p" {...{
        "href": "/configuration/#bb-bps"
      }}><inlineCode parentName="a">{`$bb-bps`}</inlineCode></a>{` variable using `}<a parentName="p" {...{
        "href": "https://sass-lang.com/documentation/modules/map#merge"
      }}><inlineCode parentName="a">{`map-merge`}</inlineCode></a>{` (which is the Sass way of doing things!).`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// in your project variables file

$bb-text-align-config: (
  'name': 'ta',
  'prop': 'text-align',
  'bps': map-merge(
    $bb-bps,
    (
      'xxl': 2600px
    )
  ),
  'values': $bb-text-align-values
);
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Classes for all breakpoints in `}<inlineCode parentName="p">{`$bp-bps`}</inlineCode>{` will be generated as well as a class for the `}<inlineCode parentName="p">{`xxl`}</inlineCode>{` breakpoint.`}</p>
    <p>{`To customize the default breakpoints refer to the `}<a parentName="p" {...{
        "href": "/configuration/#bb-bps"
      }}>{`configuration guide`}</a></p>
    <h3 {...{
      "id": "creating-a-component-using-backbreeze-values"
    }}>{`Creating a component using Backbreeze values`}</h3>
    <p>{`For complex structures it might be desirable to create a custom component. As Backbreeze is all Sass using values is fairly easy, you can use `}<inlineCode parentName="p">{`map-get`}</inlineCode>{` to retrieve values from general or property value variables or, if the values are used very often, you can create global variables and use them in your components as well as use them in your Backbreeze configuration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// _container.scss

@media all and #{map-get($bb-bps, 'sm')} {
  .container {
    max-width: map-get($bb-bps, 'sm');
  }
}
`}</code></pre>
    <p>{`Or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// _vars.scss

$bp-xs: 416px;
$bp-sm: 416px;

$bb-bps: (
  'xs': $bp-xs,
  'sm': $bp-sm,
);
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// _container.scss

@media all and #{$bp-xs} {
  .container {
    max-width: $bp-xs;
  }
}

@media all and #{$bp-sm} {
  .container {
    max-width: $bp-sm;
  }
}
`}</code></pre>
    {
      /*
      @TODO
      - creating a component
       - container
       - button
      */
    }
    {
      /* ## Creating a custom utility class
      - Buy in / Buy out (ho to incorportate & remove Backbreeze to an existing project)
      There are two ways to create custom
      - creating a custom utility
       - eg. animation
      - use values from general vars in my code / in combination with custom values
      - print styles
      - Pittfalls: order of configuration == order of class creation
      - multi property utility classes */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      